import React, { useRef, useEffect, useState, useContext } from "react";
import classes from "./ExploreOurService.module.css";
import airplane from "../assets/pics/airplane.jpg";
import { ImArrowRight2 } from "react-icons/im";
import sea from "../assets/pics/sea.jpg";
import phone from "../assets/pics/phone.jpg";
import airPackages from "../assets/pics/air-packages.jpg";
import online from "../assets/pics/online.jpg";
import supplier from "../assets/pics/supplier.jpg";
import content from "../translation/content";
import LanguageContext from "../context/language-context";
import { node } from "../env";

const ExploreOurSerivce = (props) => {
  const [isAnimate, setIsAnimate] = useState(false);

  const imgs = [airplane, sea, phone, airPackages, online, supplier];

  const cardRef = useRef();

  const { culture } = useContext(LanguageContext);

  const handleScroll = (e) => {
    if (window.scrollY / cardRef.current?.offsetTop > 0.5) {
      setIsAnimate(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={`${classes.explore} text-center`} id="our-services">
      <h2
        className={`${classes.title} font-medium leading-tight text-4xl mt-0 mb-2 font-thin`}
      >
        {content.ourServices[culture]}
      </h2>
      <p className="tracking-tight text-gray-500 md:text-lg dark:text-gray-400 tracking-widest mt-8">
        THIS IS SOME TEXT INSIDE OF A DIV BLOCK.
      </p>
      <div
        ref={cardRef}
        className={`${classes.wrapper} container max-w-screen-xl mx-auto`}
      >
        {props.cards?.map((item, index) => (
          <Service
            key={`card-${index}`}
            item={item}
            isAnimate={isAnimate}
            img={item.img}
          />
          // <div
          //   key={`card-${index}`}
          //   className={`${classes.card} ${
          //     isAnimate ? classes.slideup : ""
          //   } rounded overflow-hidden shadow-lg`}
          // >
          //   <div className={classes.imgWrapper}>
          //     {/* <img
          //       className="w-full"
          //       src={`${node}/${item.img}`?.split("\\").join("/")}
          //       alt="Sunset in the mountains"
          //     /> */}
          //     <img
          //       className="w-full"
          //       src={imgs[index]}
          //       alt="Sunset in the mountains"
          //     />
          //   </div>
          //   <div className="py-4">
          //     <div className="font-bold text-xl mb-2 uppercase">
          //       {culture === "en" ? item.name : item.arName}
          //     </div>
          //     <p className="text-gray-700 text-base">
          //       {culture === "en" ? item.content : item.arContent}
          //     </p>
          //   </div>
          //   <div className="pt-4 pb-2">
          //     <a href="/" onClick={handleReadMore} className={classes.link}>
          //       <span className={classes.arrow}>
          //         <ImArrowRight2 />
          //       </span>
          //       <span className={classes.readMore}>
          //         {content.readMore[culture]}
          //       </span>
          //     </a>
          //   </div>
          // </div>
        ))}
      </div>
    </div>
  );
};

const Service = ({ index, item, isAnimate, img }) => {
  const { culture } = useContext(LanguageContext);
  const [readMore, setReadMores] = useState(false);
  const handleReadMore = (e) => {
    e.preventDefault();
    setReadMores((r) => !r);
  };

  const style = readMore
    ? { height: "auto" }
    : {
        maxHeight: "50px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      };

  return (
    <div
      className={`${classes.card} ${
        isAnimate ? classes.slideup : ""
      } rounded overflow-hidden shadow-lg`}
    >
      <div className={classes.imgWrapper}>
        {/* <img
        className="w-full"
        src={`${node}/${item.img}`?.split("\\").join("/")}
        alt="Sunset in the mountains"
      /> */}
        <img className="w-full" src={`${node}/${img?.split("\\")?.join("/")}`} alt="Sunset in the mountains" />
      </div>
      <div className="py-4">
        <div className="font-bold text-xl mb-2 uppercase">
          {culture === "en" ? item.name : item.arName}
        </div>
        <p className="text-gray-700 text-base" style={style}>
          {culture === "en" ? item.content : item.arContent}
        </p>
      </div>
      <div className="pt-4 pb-2">
        <a href="/" onClick={handleReadMore} className={classes.link}>
          <span className={classes.arrow}>
            <ImArrowRight2 />
          </span>
          <span className={classes.readMore}>{content.readMore[culture]}</span>
        </a>
      </div>
    </div>
  );
};
export default ExploreOurSerivce;
