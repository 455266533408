import ReactLoading from 'react-loading';
// import { Section, Article, Prop } from "./generic";
import logo from "../assets/logos/shaheen-mini.png";

const Loader = ({ opacity, bg, gate, way }) => {

    const style = {
        backgroundColor: bg || '#fff',
        opacity: `${opacity ? 0.8 : 1}`,
        zIndex: 100
    }

    const logoStle = {
      width: "100px",
      height: "100px",
      objectFit: "contain"
    }
    
    return (
        <div className='loading-parent' style={style}>
            <div className="loading-child">
                <img src={logo} alt="shaheen" style={logoStle}/>
                <ReactLoading type='spin' color={way || 'var(--secondary-color)'} width="60px" height="60px" />
                <h1 style={{ color: gate || '#fff' }}>
                    {/* <span style={{ color: way || 'var(--main-color)' }}>Shaheen</span> */}
                </h1>
            </div>
        </div>
    )
};


export default Loader;