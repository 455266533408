import React from 'react'

const useFormData = () => {

  const formDataGenerator = (formData) => {
    const schema = new FormData();
    
    Object.keys(formData).forEach((key) => {
      if (typeof formData[key] === 'object') {
        const keys = Object.keys(formData[key]);
  
        for (let i = 0; i < keys.length; i++) {
          schema.append(key, formData[key][i]);
        }
      } else {
        schema.append(key, formData[key]);
      }
    });

    return schema;
  }

  return formDataGenerator;
}

export default useFormData