import { create } from 'apisauce';

const apiClient = create({
    // baseURL: "https://gis.gw.ly/api",
    // baseURL: "https://gw.shahen.shahen.ly/api"
    // baseURL: "https://shippingv2.gw.ly/api"
    // baseURL: "http://ahmedalalem-001-site1.atempurl.com/api"
    // baseURL: "https://localhost:7083/api"
    // baseURL: "http://192.168.31.115:5000/api"
    baseURL: "https://shippingv2.shahen.ly/api"
    // baseURL: "https://gw.shahen.gw.ly/api"
}); 

apiClient.addAsyncRequestTransform(async (request) => {
    const authToken = await localStorage.getItem(btoa('token'));
    if (!authToken) return;
    request.headers["Authorization"] = "Bearer " + authToken;
});

export default apiClient;