import React, { useContext } from "react";
import classes from "./Contactus.module.css";
import { BsFillPhoneVibrateFill } from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";
import { AiOutlineMail } from "react-icons/ai";
import content from "../translation/content";
import LnguageContext from "../context/language-context";

const Contactus = (props) => {

  const { culture } = useContext(LnguageContext);

  const location = culture === "en" ? props.location : props.arLocation;

  return (
    <div className={classes.contactUs} id="contact-us">
      <div className={classes.wrapper}>
        <div>
          <h2>{content.contactUs[culture]}</h2>
          <div className={classes.info}>
            <div className={classes.details}>
              <BsFillPhoneVibrateFill />
              <p>{content.callUs[culture]}</p>
            </div>
            <p className={classes.subText}>{props.phone}</p>
            <div className={classes.details}>
              <HiLocationMarker />
              <p>{content.location[culture]}</p>
            </div>
            <p className={classes.subText}>
              {location}
            </p>
            <div className={classes.details}>
              <AiOutlineMail />
              <p>{content.email[culture]}</p>
            </div>
            <p className={classes.subText}>
              {props.email}
            </p>
          </div>
        </div>
        <div className={classes.form}>
          <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
            <form action="#" className="space-y-8">
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  {content.yourEmail[culture]}
                </label>
                <input
                  type="email"
                  id="email"
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                  placeholder="name@shahen.com"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="subject"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  {content.subject[culture]}
                </label>
                <input
                  type="text"
                  id="subject"
                  className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                  placeholder={content.subjectPlaceholder[culture]}
                  required
                />
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                >
                  {content.yourMessage[culture]}
                </label>
                <textarea
                  id="message"
                  rows="6"
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder={`${content.content[culture]}...`}
                ></textarea>
              </div>
              <button
                type="submit"
                className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 secondary-bg-color"
              >
                {content.sendMessage[culture]}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
