import React, { useState } from "react";
import Modal from "./Modal";
import OrderFilter from "./OrderFilter";
import classes from "./SearchCart.module.css";
import CalculatorFilter from "./CalculatorFilter";
import Spinner from "./Spinner";
import OrderCard from "./OrderCard";

const SearchCart = () => {
  const [toggle, setToggle] = useState(true);
  const [calcModal, setCalcModal] = useState(false);
  const [orderModal, setOrderModal] = useState(false);

  const handleCancel = () => {
    setOrderModal(false);
  };

  const handleToggle = (e) => {
    e.preventDefault();
    setToggle((t) => !t);
  };

  const handleSubmit = (e) => {
    if (toggle) setOrderModal(true);
    else setCalcModal(true);
  };

  return (
    <>
      <div className={`${classes.cartWrapper} z-10 relative`}>
        {toggle ? (
          <OrderFilter handleToggle={handleToggle} callBack={handleSubmit} />
        ) : (
          <CalculatorFilter handleToggle={handleToggle} />
        )}
      </div>
    </>
  );
};

export default SearchCart;
