import { useState, useEffect } from "react";
// import { useNavigation, StackActions } from '@react-navigation/native';
import useAlert from "./useAlert";

const useApi = (apiFunc) => {
  const [data, setData] = useState();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { alert: errorAlert, sweetAlert } = useAlert();

  // const [status, SetStatus] = useState('');

  const setErrorMessage = (res) => {
    const ErrorMsg = res.data?.message;
    setError(true);
    // if there's no message output a default message
    setData(ErrorMsg || "Something went wrong");
  };

  const request = async (...args) => {
    let response;
    setError(false);
    try {
      setData(null);
      // SetStatus('');
      setLoading(true);
      response = await apiFunc(...args);
      setLoading(false);

      if (!response.ok) {
        throw new Error("Error occured");
      } else {
        setData(response.data);
      }
    } catch (err) {
      setErrorMessage(response);
    }

    return response;
    // SetStatus(response.status);
  };

  // This for cleanup
  useEffect(() => () => setLoading(false), []);

  // // if there's an error set the sweetAlert
  useEffect(() => {
    error && data && sweetAlert(data, true);
  }, [data]);

  return { data, error, setError, loading, request, setLoading, errorAlert };
};

export default useApi;
