import React, { useContext, useState } from "react";
import classes from "./SearchCart.module.css";
import Modal from "./Modal";
import CalculatorPopup from "./CalculatorPopup";
import content from "../translation/content";
import LanguageContext from "../context/language-context";

const CalculatorFilter = (props) => {
  const [category, setCategory] = useState(1);
  const [type, setType] = useState(1);
  const [iwan, setIwan] = useState("");
  const [dollar, setDollar] = useState("");
  const [country, setCountry] = useState(1);
  const [size, setSize] = useState("");

  const [modal, setModal] = useState(false);

  const { culture } = useContext(LanguageContext);

  const isShowCountry = category === 1 || category === 2 ? true : false;

  const handleCategoryChange = (e) => {
    setDollar("");
    setIwan("");
    setSize("");
    setCategory(+e.target.value)
  };

  const handleIwanChange = (e) => {
    setDollar("");
    setIwan(e.target.value);
  };

  const handleDollarChange = (e) => {
    setDollar(e.target.value);
    setIwan("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setModal(true);
  };

  const schema = {
    country,
    shipmentType: category,
    shippingType: type,
    price: dollar ? 1 : 0 || iwan ? 2 : 0,
    value: dollar || iwan || size || 0,
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className={`bg-white shadow-md rounded px-8 pt-6 pb-8 mb-10`}
      >
        <h2 className={classes.title}>{content.calculator[culture]}</h2>
        <div className="mb-10 mt-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="username"
          >
            {content.category[culture]}
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="Order number"
            value={category}
            onChange={handleCategoryChange}
          >
            <option value="1">{content.airFreight[culture]}</option>
            <option value="2">{content.seaFreight[culture]}</option>
            <option value="3">{content.transactions[culture]}</option>
          </select>
        </div>
        <div className="mb-10 mt-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="username"
          >
            {content.type[culture]}
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="Order number"
            value={type}
            onChange={(e) => setType(+e.target.value)}
          >
            <option value="1">{content.normal[culture]}</option>
            <option value="2">{content.medical[culture]}</option>
            <option value="3">{content.cncMachine[culture]}</option>
            <option value="4">{content.copy[culture]}</option>
          </select>
        </div>
        {isShowCountry && (
          <>
            <div className="mb-10 mt-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="username"
              >
                {content.country[culture]}
              </label>
              <select
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="Order number"
                value={country}
                onChange={(e) => setCountry(+e.target.value)}
              >
                <option value="1">{content.uae[culture]}</option>
                <option value="2">{content.europe[culture]}</option>
                <option value="3">{content.china[culture]}</option>
                <option value="4">{content.usa[culture]}</option>
              </select>
            </div>
            <div className="mb-10 mt-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="dollar"
              >
                {category === 1
                  ? content.weightInKg[culture]
                  : content.sizeInCbm[culture]}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="dollar"
                type="number"
                placeholder={
                  category === 1
                    ? content.weightInKg[culture]
                    : content.sizeInCbm[culture]
                }
                value={size}
                onChange={(e) => setSize(+e.target.value)}
              />
            </div>
          </>
        )}
        {category === 3 && (
          <>
            <div
              class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <span class="block sm:inline">
                {content.filledValue[culture]}
              </span>
            </div>
            <div className="mb-10 mt-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="dollar"
              >
                {content.dollarValue[culture]}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="dollar"
                type="number"
                placeholder={content.dollarValue[culture]}
                value={dollar}
                onChange={handleDollarChange}
              />
            </div>
            <div className="mb-10 mt-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="iwan"
              >
                {content.yuanValue[culture]}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="iwan"
                type="text"
                placeholder="القيمة بالايوانة"
                value={iwan}
                onChange={handleIwanChange}
              />
            </div>
            <div className="mb-10 mt-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="iwan"
              >
                {content.weightInKg[culture]}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="iwan"
                type="text"
                placeholder={content.weightInKg[culture]}
                value={size}
                onChange={(e) => setSize(+e.target.value)}
              />
            </div>
          </>
        )}
        <div className="mb-10">
          <a className={classes.anchor} href="/" onClick={props.handleToggle}>
            {content.searchForShipment[culture]}
          </a>
        </div>
        <div>
          <button
            className="w-full bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            {content.search[culture]}
          </button>
        </div>
      </form>
      {modal && (
        <Modal handleCancel={() => setModal(false)} title="الحاسبة">
          <CalculatorPopup schema={schema} />
        </Modal>
      )}
    </>
  );
};

export default CalculatorFilter;
