import React, { useContext } from "react";
import cargoShip from "../assets/pics/cargoship.jpg";
import SearchCart from "./SearchCart";
import classes from "./HeroSection.module.css";
import LanguageContext from "../context/language-context";
import { node } from "../env";

const HeroSection2 = (props) => {

  const { culture } = useContext(LanguageContext);

  const header = culture === "en" ? props.header || "" : props.arHeader || "";
  const subHeader = culture === "en" ? props.subHeader || "" : props.arSubHeader || "";

  return (
    <div>
      <div
        className="relative lg:h-screen w-full bg-cover bg-center"
        style={{ backgroundImage: `url(${node}/${props.headerImg?.split("\\")?.join("/")})` }}
      >
        <div className="absolute top-0 right-0 bottom-0 left-0 bg-gray-900 opacity-75"></div>
        <div className="flex items-center justify-start text-left max-w-screen-xl h-screen mx-auto">
          <main className={`${classes.content}  px-10 lg:px-0 relative`}>
            <div className={`text-${culture === "ar" ? "right" : "left"} max-w-screen-xl`}>
              <h2 className="text-4xl tracking-tight leading-10 font-extrabold sm:text-5xl text-white sm:leading-none md:text-6xl">
                {header.split(" ").length > 1 ? header.split(" ").slice(0, -1).join(" ") : ""}
                <span className="secondary-color"> {header?.split(" ").pop()}</span>
              </h2>
              <p className="mt-3 text-white sm:mt-5 sm:max-w-xl md:mt-5 text-lg font-light">
                {subHeader}
              </p>
            </div>
          </main>
        </div>
          <SearchCart />
      </div>
    </div>
  );
};

export default HeroSection2;
