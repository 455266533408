import React, { useContext } from "react";
import classes from "./OurOffice.module.css";
import office1 from "../assets/pics/office-1.jpg";
import office2 from "../assets/pics/office-2.jpg";
import office3 from "../assets/pics/office-3.jpg";
import office4 from "../assets/pics/office-4.jpg";
import office5 from "../assets/pics/office-5.jpg";
import office6 from "../assets/pics/office-6.jpg";
import content from "../translation/content";
import LanguageContext from "../context/language-context";
import { node } from "../env";

const OurOffice = (props) => {

  const { culture } = useContext(LanguageContext);
  
  return (
    <div className={classes.office} id="our-envirment">
      <div className="text-center">
        <h2 className="font-medium leading-tight text-4xl mt-0 mb-2 font-thin">
          {content.ourEnvirment[culture]}
        </h2>
      </div>
      <div className={classes.gallary}>
      {props.environments?.map((item, index) => (
        <div key={`environments-${index}`} className={classes.imgWrapper}>
          <div>
            <img src={`${node}/${item?.split("\\")?.join("/")}`} alt="office-1" />
          </div>
        </div>
      ))}
      </div>
    </div>
  );
};

export default OurOffice;
