import React, { useEffect, useState } from "react";
import classes from "./OrderCard.module.css";
import ship from "../assets/pics/ship.svg";
import { BsCheckLg } from "react-icons/bs";
import Spinner from "./Spinner";
import bulk from "../api/bulk";
import useApi from "../hooks/useApi";

const OrderCard = (props) => {
  const [data, setData] = useState({});
  const bulkApi = useApi(bulk.filter);

  const getBulks = async () => {
    const shipmentType = props.category === 1 ? "aircargo" : "lcl";

    const res = await bulkApi.request(shipmentType, props.orderNo);
    if (res.status === 200) {
      setData(res.data.data[0]);
    }
  };

  useEffect(() => {
    getBulks();
  }, []);

  if (bulkApi.loading) return <Spinner />;

  if (!data?.hasOwnProperty("orderId")) return <div className="text-white text-center">Not found</div>

  return (
    <div className={classes.body}>
      <section className={classes.root}>
        <figure>
          <img src={ship} alt="ship" />
          <figcaption>
            <h4>Tracking Details</h4>
            <h6>Order Number</h6>
            <h2>{props.orderNo}</h2>
          </figcaption>
        </figure>
        <div className={classes["order-track"]}>
          <div className={classes["order-track-step"]}>
            <div className={classes["order-track-status"]}>
              <span
                className={
                  data.recieveDate
                    ? classes["order-track-status-dot"]
                    : classes["order-dot-not-recived"]
                }
              >
                <BsCheckLg />
              </span>
              <span
                className={
                  data.recieveDate
                    ? classes["order-track-status-line"]
                    : classes["order-line-not-recived"]
                }
              ></span>
            </div>
            <div className={classes["order-track-text"]}>
              <p className={classes["order-track-text-stat"]}>Recived Date</p>
              <span className={classes["order-track-text-sub"]}>
                {data.recieveDate?.split("T")[0]}
              </span>
            </div>
          </div>
          <div className={classes["order-track-step"]}>
            <div className={classes["order-track-status"]}>
              <span
                className={
                  data.exportDate
                    ? classes["order-track-status-dot"]
                    : classes["order-dot-not-recived"]
                }
              >
                <BsCheckLg />
              </span>
              <span
                className={
                  data.exportDate
                    ? classes["order-track-status-line"]
                    : classes["order-line-not-recived"]
                }
              ></span>
            </div>
            <div className={classes["order-track-text"]}>
              <p className={classes["order-track-text-stat"]}>Exported Date</p>
              <span className={classes["order-track-text-sub"]}>
                {data.exportDate?.split("T")[0]}
              </span>
            </div>
          </div>
          <div className={classes["order-track-step"]}>
            <div className={classes["order-track-status"]}>
              <span
                className={
                  data.arrivalDate
                    ? classes["order-track-status-dot"]
                    : classes["order-dot-not-recived"]
                }
              >
                <BsCheckLg />
              </span>
              <span className={classes["order-track-status-line"]}></span>
            </div>
            <div className={classes["order-track-text"]}>
              <p className={classes["order-track-text-stat"]}>Arrival Date</p>
              <span className={classes["order-track-text-sub"]}>
                {data.arrivalDate?.split("T")[0]}
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OrderCard;
