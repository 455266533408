import { useReducer } from "react";
import LanguageContext from "./language-context";

const initialState = {
  culture: 'ar',
  toggleLang: () => {}
}

const langReducer = (state, action) => {
  if (action.type === 'TOGGLE') {
    return {
      ...state,
      culture: state.culture === 'en' ? 'ar' : 'en'
    }
  }
  return state;
}

const LanguageProvider = props => {

  const [langState, dispatchLang] = useReducer(langReducer, initialState);

  const toggleLang = () => {
    dispatchLang({ type: 'TOGGLE' })
  }

  const langContext = {
    culture: langState.culture,
    toggleLang
  }

  return (
    <LanguageContext.Provider value={langContext}>
      {props.children}
    </LanguageContext.Provider>
  )
}

export default LanguageProvider;