import { useContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import "../App.css";
import Navbar from "../components/Navbar";
import HeroSection2 from "../components/HeroSection2";
import LanguageContext from "../context/language-context";
import ExploreOurSerivce from "../components/ExploreOurSerivce";
import PricingTable from "../components/PricingTable";
import Service from "../components/Service";
import OurOffice from "../components/OurOffice";
import Contactus from "../components/Contactus";
import Footer from "../components/Footer";
import useApi from "../hooks/useApi";
import templateApi from "../api/templateApi";
import Loader from "../components/Loader";

function Home() {
  const { culture } = useContext(LanguageContext);

  const [data, setData] = useState({});

  const template = useApi(templateApi.getPortfolio);

  const preload = async () => {
    const res = await template.request();
    if (res.status === 200);
    setData(res.data.data);
  };

  useEffect(() => {
    preload();
  }, []);

  if (template.loading) return <Loader />;

  return (
    <div className={culture}>
      <Navbar />
      <HeroSection2
        header={data.header}
        arHeader={data.arHeader}
        subHeader={data.subHeader}
        arSubHeader={data.arSubHeader}
        headerImg={data.headerImg}
      />
      {/* <Routes> */}
      {/* <Route path="/" element={<Home />} /> */}
      {/* <Route path="/*" element={<Home />} /> */}
      {/* </Routes> */}
      <ExploreOurSerivce cards={data.cards} />
      <PricingTable
        priceTableAir={data.priceTableAir}
        priceTableOcean={data.priceTableOcean}
      />
      <Service
        aboutImg={data.aboutImg}
        aboutHeader={data.aboutHeader}
        arAboutHeader={data.arAboutHeader}
        aboutContent={data.aboutContent}
        arAboutContent={data.arAboutContent}
      />
      <OurOffice environments={data.environments} />
      <Contactus email={data.email} phone={data.phone} location={data.location} arLocation={data.arLocation} />
      <Footer />
    </div>
  );
}

export default Home;
