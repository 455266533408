import React, { useEffect, useState } from "react";
import calculator from "../api/calculator";
import useApi from "../hooks/useApi";
import Spinner from "./Spinner";
import classes from "./CalculatorPopup.module.css";
import { FaCrown } from "react-icons/fa";

const CalculatorPopup = (props) => {
  const [data, setData] = useState({});

  const calcApi = useApi(calculator.calculate);

  const filter = async () => {
    const res = await calcApi.request(props.schema);

    if (res.status === 200) {
      setData(res.data.data);
    }
  };

  useEffect(() => {
    filter();
  }, []);

  if (calcApi.loading) return <Spinner />;

  return (
    <div className={`${classes.calc} text-white`}>
      <div className={classes.normal}>
        <p className={classes.price}>${data.normal}</p>
        <p>التكلفة للحسابات العادية</p>
      </div>
      <div className={classes.vip}>
        <div className={classes.icon}>
          <FaCrown />
        </div>
        <p className={classes.price}>${data.vip}</p>
        <p>التكلفة للحسابات البلس</p>
      </div>
    </div>
  );
};

export default CalculatorPopup;
