import React, { useContext } from "react";
import shaheenLogo from "../assets/logos/shaheen-mini.png";
import LanguageContext from "../context/language-context";
import content from "../translation/content";
import { useForm } from "react-hook-form";
import env from "../env";
import { signup } from "../api/signup";
import useApi from "../hooks/useApi";
import useFormData from "../hooks/useFormData";
import useAlert from "../hooks/useAlert";
import { useState } from "react";

const Signup = () => {
  const signupApi = useApi(signup);
  const { culture, toggleLang } = useContext(LanguageContext);
  const formDataGenerator = useFormData();
  const [loading, setLoading] = useState(false);
  const { alert, sweetAlert } = useAlert();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    userType: 1,
  });

  const submitHandler = async (data) => {
    setLoading(true);
    const schema = formDataGenerator(data);
    const res = await signupApi.request(schema);
    if (res.status === 200) {
      sweetAlert(content.registered[culture]);
      reset();
    }
    setLoading(false);
  };

  return (
    <div>
      {alert}
      {signupApi.errorAlert}
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
          <a
            href="#"
            className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
          >
            <img
              className="w-10 h-10 mr-2 object-contain"
              src={shaheenLogo}
              alt="logo"
            />
            {content.shaheen[culture]}
          </a>
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <div className="flex justify-between">
                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  {content.singup[culture]}
                </h1>
                <button
                  onClick={toggleLang}
                  className="text-gray-800 dark:text-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
                >
                  {culture === "en" ? "العربية" : "English"}
                </button>
              </div>
              <form
                onSubmit={handleSubmit(submitHandler)}
                className="space-y-4 md:space-y-6"
              >
                <div>
                  <label
                    htmlFor="accountCategory"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {content.accountCategory[culture]}
                  </label>
                  <select
                    {...register("userType", { required: true })}
                    type="select"
                    name="userType"
                    id="accountCategory"
                    className={`
											${errors.userType ? "error" : ""}
											bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500
										`}
                  >
                    {env.userType.map((type, index) => (
                      <option value={index + 1} key={index}>
                        {type[culture]}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="fullName"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {content.fullName[culture]}
                  </label>
                  <input
                    {...register("fullName", { required: true })}
                    type="text"
                    name="fullName"
                    id="fullName"
                    className={`
											${errors.fullName ? "error" : ""}
											bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500
										`}
                    placeholder={content.fullName[culture]}
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {content.email[culture]}
                  </label>
                  <input
                    {...register("email", { required: true })}
                    type="email"
                    name="email"
                    id="email"
                    className={`
											${errors.email ? "error" : ""}
											bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500
										`}
                    placeholder="name@company.com"
                  />
                </div>
                <div>
                  <label
                    htmlFor="username"
                    className={`
											${errors.username ? "error" : ""}	
											block mb-2 text-sm font-medium text-gray-900 dark:text-white
										`}
                  >
                    {content.username[culture]}
                  </label>
                  <input
                    {...register("username", { required: true })}
                    type="text"
                    name="username"
                    id="username"
                    className={`
											${errors.username ? "error" : ""}
											bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500
										`}
                    placeholder={content.username[culture]}
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {content.password[culture]}
                  </label>
                  <input
                    {...register("password", { required: true })}
                    type="password"
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    className={`
											${errors.password ? "error" : ""}
											bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500
										`}
                  />
                </div>
                <div>
                  <label
                    htmlFor="confirm-password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {content.confirmPassword[culture]}
                  </label>
                  <input
                    {...register("confirmPassword", {
                      required: true,
                    })}
                    type="password"
                    name="confirmPassword"
                    id="confirm-password"
                    placeholder="••••••••"
                    className={`
											${errors.confirmPassword ? "error" : ""}
										bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  />
                </div>
                <div>
                  <label
                    htmlFor="imges"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {content.files[culture]}
                  </label>
                  <input
                    {...register("image", {
                      required: true,
                    })}
                    type="file"
                    name="image"
                    id="files"
                    className={`
											${errors.image ? "error" : ""}
										bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  />
                </div>
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      {...register("terms", { required: true })}
                      id="terms"
                      aria-describedby="terms"
                      type="checkbox"
                      className={`
												${errors.terms ? "error" : ""}
												w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800
											`}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="terms"
                      className="font-light text-gray-500 dark:text-gray-300"
                    >
                      <a
                        className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                        target="_blank"
                        href="https://shippingv2.shahen.ly/images/شروط وسياسة الخدمة الصين.pdf"
                      >
                        {content.terms[culture]}
                      </a>
                    </label>
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={loading}
                  className="w-full text-white bg-blue-500 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  {loading ? "..." : content.createAccount[culture]}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Signup;
