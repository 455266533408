import React, { Fragment, useContext } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import shaheenLogo from "../assets/logos/shaheen-mini.png";
import LanguageContext from "../context/language-context";
import content from "../translation/content";
import { Link } from "react-router-dom";

const Header = () => {
  const { culture, toggleLang } = useContext(LanguageContext);

  const navigation = [
    { name: content.ourServices[culture], href: "our-services" },
    { name: content.prices[culture], href: "pricing" },
    { name: content.aboutUs[culture], href: "about" },
    { name: content.ourEnvirment[culture], href: "our-envirment" },
    { name: content.contactUs[culture], href: "contact-us" },
  ];

  const handleNavigate = (e, item) => {
    e.preventDefault();
    const element = document.getElementById(item.href);
    element && element.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <Popover>
      <nav className="bg-transparent z-10 border-gray-200 px-4 lg:px-6 py-2.5 absolute inset-x-0">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <a href="https://www.shahen.ly" className="flex items-center">
            <img
              src={shaheenLogo}
              className="mr-3 h-6 sm:h-9"
              alt="Flowbite Logo"
            />
            <span className="ms-1 self-center text-xl font-semibold whitespace-nowrap dark:text-white">
              {content.shaheen[culture]}
            </span>
          </a>
          <div className="flex items-center lg:order-2">
            <a
              href="#"
              onClick={toggleLang}
              className="text-gray-800 dark:text-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
            >
              {culture === "en" ? "العربية" : "English"}
            </a>
            <div className="-mr-2 flex items-center md:hidden">
              <Popover.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-200 hover:bg-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-main-color dark:bg-gray-800">
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
          </div>
          <div
            className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1"
            id="mobile-menu-2"
          >
            <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
              {navigation.map((item) => (
                <li key={item.href}>
                  <a
                    href={item.href}
                    onClick={(e) => handleNavigate(e, item)}
                    className="me-1 block py-2 pr-4 pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"
                    aria-current="page"
                  >
                    {item.name}
                  </a>
                </li>
              ))}
              <li>
                <Link
                  to="/signup"
                  className="me-1 block py-2 pr-4 pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"
                >
                  {content.singup[culture]}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <Transition
        as={Fragment}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden"
        >
          <div className="overflow-hidden rounded-lg dark:bg-gray-800 shadow-md ring-1 ring-black ring-opacity-5">
            <div className="flex items-center justify-between px-5 pt-4">
              <div>
                <img className="h-8 w-auto" src={shaheenLogo} alt="" />
              </div>
              <div className="-mr-2">
                <Popover.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-200 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-main-color">
                  <span className="sr-only">Close main menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="block rounded-md px-3 py-2 text-base font-medium text-gray-100 hover:bg-gray-500 hover:text-gray-200"
                  onClick={(e) => handleNavigate(e, item)}
                >
                  {item.name}
                </a>
              ))}
            </div>
            <Link
              to="/signup"
              className="block w-full bg-gray-600 px-5 py-3 text-center font-medium text-gray-200 hover:bg-gray-700"
            >
              {content.singup[culture]}
            </Link>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default Header;
