import React, { useContext } from "react";
import classes from "./PricingTable.module.css";
import LanguageContext from "../context/language-context";
import content from "../translation/content";

const PricingTable = (props) => {
  const { culture } = useContext(LanguageContext);

  const unit = culture === "en" ? props.unit : props.arUnit;
  
  const getCard = (field, item, index) => {
    const darkCard = (
      <div
        className={`${classes.startup} w-80 p-8 bg-gray-900 text-center rounded-3xl text-white border-4 shadow-xl border-white transform scale-125`}
      >
        <h1 className="text-white font-semibold text-2xl">
          {culture === "en" ? item.title : item.arTitle}
        </h1>
        <p className="pt-2 tracking-wide en">
          <span className="text-gray-400 align-top">$ </span>
          <span className="text-3xl font-semibold">{item.price}</span>
          <span className="text-gray-400 font-medium">
            / {item.unit}
          </span>
        </p>
        <hr className="mt-4 border-1 border-gray-600" />
        <div className="pt-8">
          {item[culture === "en" ? "subText" : "arSubText"]?.map(
            (item, index) => (
              <p
                key={`subText-${index}`}
                className="font-semibold text-gray-400 text-left"
              >
                {/* <span className="material-icons align-middle">done</span> */}
                <span className="pl-2">
                  {item?.split(" ").length > 1 ? item.split(" ").slice(0, -1).join(" ") : ""}
                  <span className="text-white">
                    {item.split(" ").pop()}
                  </span>
                </span>
              </p>
            )
          )}
        </div>
        <div className="absolute top-4 right-4"></div>
      </div>
    );

    const whiteCard = (
      <div
        key={`price-${index}`}
        className="w-96 p-8 bg-white text-center rounded-3xl pr-16 shadow-xl"
      >
        <h1 className="text-black font-semibold text-2xl">
          {culture === "en" ? item.title : item.arTitle}
        </h1>
        <p className="pt-2 tracking-wide en">
          <span className="text-gray-400 align-top">$ </span>
          <span className="text-3xl font-semibold">{item.price}</span>
          <span className="text-gray-400 font-medium">
            / {item.unit}
          </span>
        </p>
        <hr className="mt-4 border-1" />
        <div className="pt-8">
          {item[culture === "en" ? "subText" : "arSubText"]?.map(
            (item, index) => (
              <p
                key={`subText-${index}`}
                className="font-semibold text-gray-400 text-left"
              >
                <span className="pl-2">
                  {item?.split(" ").length > 1 ? item.split(" ").slice(0, -1).join(" ") : ""}
                  <span className="text-black">
                    {" "}
                    {item?.split(" ").pop()}
                  </span>
                </span>
              </p>
            )
          )}
        </div>
      </div>
    );
    const sequence = [1, 4, 7, 10, 13];
    if (props[field].length === 1) return darkCard;

    if (sequence.includes(index)) return darkCard;

    return whiteCard;
  };
  return (
    <div className="font-sans bg-gray-100 pt-10" id="pricing">
      <div className="min-h-screen flex justify-center items-center">
        <div className="">
          <div className="text-center font-semibold">
            <h2
              className={`title font-medium leading-tight text-4xl mt-0 mb-2 font-thin`}
            >
              {content.plans[culture]}
            </h2>
            <p className="pt-6 text-xl text-gray-400 font-normal w-full px-8 md:w-full">
              {content.airPlans[culture]}
            </p>
          </div>
          <div
            className={`${classes.pricing} pt-24 flex flex-row ${
              props.priceTableAir?.length < 3 ? "justify-evenly" : ""
            } `}
          >
            {props.priceTableAir?.map((item, index) =>
              getCard("priceTableAir", item, index)
            )}
          </div>
          <div className="text-center font-semibold">
            <p className="pt-6 text-xl text-gray-400 font-normal w-full px-8 md:w-full">
              {content.seaPlans[culture]}
            </p>
          </div>
          <div className={`${classes.pricing} pt-24 flex flex-row ${
              props.priceTableAir?.length < 3 ? "justify-evenly" : ""
          }`}>
          {props.priceTableOcean?.map((item, index) =>
              getCard("priceTableOcean", item, index)
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingTable;
