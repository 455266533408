import { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import LanguageContext from "./context/language-context";
import Signup from "./pages/Signup";

function App() {
  const { culture } = useContext(LanguageContext);

  return (
    <div className={culture}>
      <Routes>
        <Route path="/signup" element={<Signup />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
