import React, { useState, useContext } from "react";
import classes from "./SearchCart.module.css";
import Modal from "./Modal";
import OrderCard from "./OrderCard";
import LanguageContext from "../context/language-context";
import content from "../translation/content";

const OrderFilter = (props) => {
  const [category, setCategory] = useState(1);
  const [orderNo, setOrderNo] = useState("");
  const [orderModal, setOrderModal] = useState(false);

  const { culture } = useContext(LanguageContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    setOrderModal(true);
  };
  
  return (
    <>
      <form
        onSubmit={handleSubmit}
        className={`bg-white shadow-md rounded px-8 pt-6 pb-8 mb-10`}
      >
        <h2 className={classes.title}>{content.shipments[culture]}</h2>
        <div className="mb-10 mt-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="username"
          >
            {content.category[culture]}
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="Order number"
            value={category}
            onChange={(e) => setCategory(+e.target.value)}
          >
            <option value="1">{content.airFreight[culture]}</option>
            <option value="2">{content.seaFreight[culture]}</option>
          </select>
        </div>
        <div className="mb-10 mt-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="order-number"
          >
            {content.deliveryNumber[culture]}
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="order-number"
            type="text"
            placeholder={content.deliveryNumber[culture]}
            value={orderNo}
            onChange={(e) => setOrderNo(e.target.value)}
          />
        </div>
        <div className="mb-10">
          <a className={classes.anchor} href="/" onClick={props.handleToggle}>
            {content.calculator[culture]}
          </a>
        </div>
        <div>
          <button
            className="w-full bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            {content.search[culture]}
          </button>
        </div>
      </form>
      {orderModal && (
        <Modal handleCancel={() => setOrderModal(false)}>
          <OrderCard category={category} orderNo={orderNo} />
        </Modal>
      )}
    </>
  );
};

export default OrderFilter;
