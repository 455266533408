export default {
  shipments: {
    ar: "الشحنات",
    en: "Shipments",
  },
  category: {
    ar: "الفئة",
    en: "Category",
  },
  seaFreight: {
    ar: "شحن بحري",
    en: "Sea freight",
  },
  airFreight: {
    ar: "شحن جوي",
    en: "Air freight",
  },
  deliveryNumber: {
    ar: "رقم الشحنة",
    en: "Delivery number",
  },
  calculator: {
    ar: "الة الحاسبة",
    en: "Calculator.",
  },
  search: {
    ar: "بحث",
    en: "Search",
  },
  transactions: {
    ar: "حوالات",
    en: "Transactions",
  },
  normal: {
    ar: "عادي",
    en: "Normal",
  },
  medical: {
    ar: "طبي",
    en: "Medical",
  },
  cncMachine: {
    ar: "مكينات cnc",
    en: "cnc machine",
  },
  copy: {
    ar: "كوبي",
    en: "Copy",
  },
  country: {
    ar: "البلد",
    en: "Country",
  },
  uae: {
    ar: "الامارات",
    en: "UAE",
  },
  europe: {
    ar: "أوروبا",
    en: "Europe",
  },
  usa: {
    ar: "الولايات المتحدة الأمريكية",
    en: "USA",
  },
  china: {
    ar: "الصين",
    en: "China",
  },
  weightInKg: {
    ar: "الوزن بالكيلو جرام",
    en: "Weight in kg",
  },
  searchForShipment: {
    ar: "البحث عن الشحنات",
    en: "Search for shipment",
  },
  ourServices: {
    ar: "اكتشف خدماتنا",
    en: "Explore our services",
  },
  readMore: {
    ar: "قراءة المزيد",
    en: "Read more",
  },
  shaheen: {
    ar: "الشاهين",
    en: "Shaheen",
  },
  plans: {
    ar: "خطط الشاهين",
    en: "Shaheen Plans",
  },
  kg: {
    ar: "كيلو جرام",
    en: "Kg",
  },
  seaPlans: {
    ar: "خطط الشحن البحرية",
    en: "Sea shipping plans",
  },
  airPlans: {
    ar: "خطط الشخن الجوية",
    en: "Air shipping plans",
  },
  ourEnvirment: {
    ar: "بيئتنا",
    en: "Our Envirment",
  },
  contactUs: {
    ar: "تواصل معنا",
    en: "Contact us",
  },
  callUs: {
    ar: "اتصل بنا",
    en: "Call us",
  },
  location: {
    ar: "الموقع",
    en: "Location",
  },
  yourEmail: {
    ar: "البريد الخاص بك",
    en: "your email",
  },
  subject: {
    ar: "الموضوع",
    en: "Subject",
  },
  yourMessage: {
    ar: "رسالتك",
    en: "Your message",
  },
  sendMessage: {
    ar: "ارسال",
    en: "Send message",
  },
  subjectPlaceholder: {
    ar: "اخبرنا كيف يمكننا مساعدتك",
    en: "Let us know how we can help you",
  },
  content: {
    ar: "المحتوى",
    en: "Content",
  },
  prices: {
    ar: "أسعارنا",
    en: "Our prices",
  },
  aboutUs: {
    ar: "معلومات عنا",
    en: "About us",
  },
  type: {
    ar: "النوع",
    en: "Type",
  },
  sizeInCbm: {
    ar: "الحجم بال CBM",
    en: "Size in CBM",
  },
  filledValue: {
    ar: "يمكنك ادخال احد الخانتين المبلغ بالايوانة او المبلغ بالدولار",
    en: "You can enter either of the two fields the amount in yuan or the amount in dollars",
  },
  dollarValue: {
    ar: "القيمة بالدولار",
    en: "Dollar value",
  },
  yuanValue: {
    ar: "القيمة بالايوانة",
    en: "Yuan value",
  },
  email: {
    ar: "البريد الالكتروني",
    en: "Email",
  },
  singup: {
    ar: "انشاء حساب",
    en: "Sign up"
  },
  accountCategory: {
    ar: "فئة الحساب",
    en: "Account category"
  },
  fullName: {
    ar: "الاسم الكامل",
    en: "Full name"
  },
  whatsup: {
    ar: "رقم الهاتف المسجل في الواتس اب",
    en: "WhatsApp phone number"
  },
  email: {
    ar: "البريد الالكتروني",
    en: "Email"
  },
  username: {
    ar: "اسم المستخدم",
    en: "Username"
  },
  password: {
    ar: "كلمة السر",
    en: "Password"
  },
  confirmPassword: {
    ar: "تأكيد كلمة السر",
    en: "Confirm password"
  },
  files: {
    ar: "قم بارفاق صورة من تعريف هويتك من فضلك",
    en: "Please attach a copy of your identification"
  },
  terms: {
    ar: "يجب قراءة شروط التسجيل والموافقة عليها",
    en: "You must read and agree to the terms of registration"
  },
  createAccount: {
    ar: "انشاء حساب",
    en: "Create account"
  },
  registered: {
    ar: "تم التسجيل يمكنك استخدام التطبيق الان",
    en: "Registered, you can use the application now"
  }
};
