import React, { useContext } from "react";
import classes from "./Service.module.css";
import ship from "../assets/pics/service.jpg";
import LanguageContext from "../context/language-context";
import { node } from "../env";

const Service = (props) => {
  const { culture } = useContext(LanguageContext);
  const aboutHeader =
    culture === "en" ? props.aboutHeader : props.arAboutHeader;
  const aboutContent =
    culture === "en" ? props.aboutContent : props.arAboutContent;
  // const aboutHeader = culture === "en" ? props.aboutHeader : props.arAboutContent;

  return (
    <div className={classes.service} id="about">
      <div className="container max-w-screen-xl mx-auto">
        <div
          className={`${classes.contentWrapper} justify-between items-center`}
        >
          <div className={classes.content}>
            <h2>{aboutHeader}</h2>
            <p>{aboutContent}</p>
          </div>
          <div className={classes.imgWrapper}>
            <img src={`${node}/${props.aboutImg?.split("\\").join("/")}`} alt="ship" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
